// you can import modules from the theme lib or even from
// NPM packages if they support it…
import ExampleComponent1 from "./components/ExampleComponent1";

// you can also require modules if they support it…
const ExampleModule2 = require("./components/example-2");

// Some convenient tools to get you started…
import ReplaceObfuscatedEmailAddresses from "./components/ReplaceObfuscatedEmailAddresses";
import AnimateOnPageLinks from "./components/AnimateOnPageLinks";

// Initialise our components on jQuery.ready…
// jQuery(function ($) {
//     ExampleComponent1.init();
//     ExampleModule2.init();
//     ReplaceObfuscatedEmailAddresses.init();
//     AnimateOnPageLinks.init();
// });
window.$ = require("jquery");
window.axios = require("axios");
require("./nav.js");
var Flickity = require("flickity");
import "intersection-observer";
const scrollama = require("scrollama");

const scroller = scrollama();

var offset = 0.7;
$(function() {
    if ($(".box").length > 0) {
        scroller
            .setup({
                step: ".box",
                offset,
                threshold: 1,
            })
            .onStepEnter((response) => {
                // { element, index, direction }
                if (response.direction == "down") {
                    window.dispatchEvent(
                        new Event("animate-box-" + response.element.id)
                    );
                }
            })
            .onStepExit((response) => {
                // { element, index, direction }
                if (response.direction == "up") {
                    window.dispatchEvent(
                        new Event("unanimate-box-" + response.element.id)
                    );
                }
            })
            .onStepProgress((response) => {});
    }
    var noticeHeight = $("#notice").length > 0 ? $("#notice").outerHeight() : 0;
    if (Detectizr.device.type == "desktop") {
        $(window).on("scroll", function(e) {
            var top = $(e.target).scrollTop();
            if (top > noticeHeight) {
                $("body").addClass("sticky");
            } else if (top <= noticeHeight) {
                $("body").removeClass("sticky");
            }
        });
    }
});

// setup resize event
window.addEventListener("resize", scroller.resize);

var validator = require("email-validator");
window.newsletterForm = function() {
    var message = "";
    return {
        message,
        checkForm() {
            var email = $(".newsletter .email").val();
            if (!validator.validate(email)) {
                this.message = "Invalid email format";
            } else {
                axios.get(signup_url + "&email=" + email).then((response) => {
                    if (response.data.success == 1) {
                        window.dispatchEvent(
                            new CustomEvent("newsletter-message", {
                                detail: { message: response.data.message },
                            })
                        );
                    } else {
                        window.dispatchEvent(
                            new CustomEvent("newsletter-message", {
                                detail: { message: "Server Error" },
                            })
                        );
                    }
                });
            }
            return false;
        },
    };
};
